<script>
import ReturnStatusesForm from './ReturnStatusesForm'

export default {
  extends: ReturnStatusesForm,

  created() {
    this.getReturnStatus(this.$route.params.id).then(response => {
      this.form = {
        ...response,
        is_validate: response.is_validate || false
      }
    })
  },

  methods: {
    onSubmit() {
      this.updateReturnStatus(this.form.id, this.form)
        .then(() => {
          this.successSubmit({ message: 'Статус успешно изменен', routeName: 'return-statuses' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>
